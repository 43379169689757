import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TitleStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ConcatTitleStrategy extends TitleStrategy {
    public static readonly BASE_TITLE = 'Payroll Integrations';

    public readonly title = inject(Title);

    public updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.concatTitle(routerState.root, ConcatTitleStrategy.BASE_TITLE, ' - ');
        if (title) {
            this.title.setTitle(title);
        }
    }

    private concatTitle(route: ActivatedRouteSnapshot | null, title: string, separator: string): string {
        if (!route) {
            return title;
        }

        // Only apply the title if the route has a title in its config
        const sub = route.routeConfig?.title ? this.getResolvedTitleForRoute(route) : undefined;
        if (sub) {
            title = `${sub}${separator}${title}`;
        }

        const childSnapshot = route.firstChild;
        title = this.concatTitle(childSnapshot, title, separator);

        return title;
    }
}

export const ConcatTitleStrategyProvider = {
    provide: TitleStrategy,
    useClass: ConcatTitleStrategy
};
