import { PRIVACY_POLICY_PATH, TERMS_AND_CONDITIONS_PATH } from './shared/constants/route-paths';
import { allowsNewAccountCreationGuard } from './shared/guards/allows-new-account-creation.guard';
import { authGuard } from './shared/guards/auth.guard';
import { hasPermissionsGuard } from './shared/guards/has-permissions.guard';
import { logoutGuard } from './shared/guards/logout.guard';
import { queryParamExistsGuard } from './shared/guards/query-param-exists.guard';
import { recordKeeperGuard } from './shared/guards/record-keeper.guard';
import { supportedRecordKeeperGuard } from './shared/guards/supported-record-keeper.guard';
import { supportedTPAGuard } from './shared/guards/supported-tpa.guard';
import { userSetupGuard } from './shared/guards/user-setup.guard';
import { mainTabsResolver } from './shared/resolvers/main-tabs.resolver';
import { planSponsorBreadcrumbResolver } from './shared/resolvers/plan-sponsor-breadcrumb.resolver';
import { Breadcrumb } from './shared/types/breadcrumb';
import type { PiRoute } from './shared/types/pi-route';

export const ROUTES: PiRoute[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login'
    },
    {
        // Old admin route redirects to login
        path: 'admin',
        redirectTo: '/login'
    },
    {
        path: 'login',
        loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
        title: 'Login'
    },
    {
        path: 'logout',
        canActivate: [logoutGuard],
        loadComponent: () => import('./empty/empty.component').then((m) => m.EmptyComponent)
    },
    {
        path: 'confirmation',
        loadComponent: () =>
            import('./lib/components/redirect-confirmation/redirect-confirmation.component').then(
                (m) => m.RedirectConfirmationComponent
            )
    },
    {
        path: 'voya',
        redirectTo: '/payint/login'
    },
    {
        path: TERMS_AND_CONDITIONS_PATH,
        loadComponent: () =>
            import('./terms-and-conditions/terms-and-conditions.component').then((m) => m.TermsAndConditionsComponent),
        title: 'Terms and Conditions'
    },
    {
        path: PRIVACY_POLICY_PATH,
        loadComponent: () => import('./privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
        title: 'Privacy Policy'
    },
    {
        path: 'tpa/:supportedtpa',
        loadComponent: () =>
            import('./router-outlet-wrapper/router-outlet-wrapper.component').then(
                (m) => m.RouterOutletWrapperComponent
            ),
        canActivate: [supportedTPAGuard],
        children: [
            {
                path: 'onboarding',
                loadComponent: () =>
                    import('./tpa-onboarding/tpa-onboarding.component').then((m) => m.TPAOnboardingComponent)
            }
        ]
    },
    {
        path: 'onboard2',
        loadChildren: () => import('./onboarding/onboarding.routes').then((m) => m.ONBOARDING_ROUTES)
    },
    {
        path: ':supportedRecordKeeper',
        loadComponent: () =>
            import('./router-outlet-wrapper/router-outlet-wrapper.component').then(
                (m) => m.RouterOutletWrapperComponent
            ),
        canActivate: [supportedRecordKeeperGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'plan-sponsors'
            },
            {
                path: 'login',
                loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
                title: 'Login'
            },
            {
                path: 'reset-password',
                canActivate: [queryParamExistsGuard('username'), queryParamExistsGuard('secret')],
                loadComponent: () =>
                    import('./reset-password/reset-password.component').then((m) => m.ResetPasswordComponent)
            },
            {
                path: 'forgot-password',
                loadComponent: () =>
                    import('./forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
                title: 'Forgot Password'
            },
            {
                path: 'create-account',
                canActivate: [allowsNewAccountCreationGuard],
                loadComponent: () =>
                    import('./create-account/create-account.component').then((m) => m.CreateAccountComponent),
                title: 'Create Account'
            },
            {
                path: 'verify-account',
                canActivate: [queryParamExistsGuard('email'), queryParamExistsGuard('token')],
                loadComponent: () =>
                    import('./verify-account/verify-account.component').then((m) => m.VerifyAccountComponent)
            },
            {
                path: 'setup', // Empty page where series of modals can get initial required setup for use like TOS
                loadChildren: () => import('./setup/setup.routes').then((m) => m.SETUP_ROUTES),
                title: 'Setup'
            },
            {
                path: 'onboarding',
                loadComponent: () =>
                    import('./record-keeper-onboarding/record-keeper-onboarding.component').then(
                        (m) => m.RecordKeeperOnboardingComponent
                    ),
                title: 'Onboarding'
            },
            {
                path: '',
                loadComponent: () =>
                    import('./app-main-layout/app-main-layout.component').then((m) => m.AppMainLayoutComponent),
                canActivate: [authGuard, recordKeeperGuard, userSetupGuard],
                data: {
                    breadcrumb: new Breadcrumb('Home')
                },
                children: [
                    {
                        path: 'plan-sponsors',
                        loadComponent: () =>
                            import('./plan-sponsor-table/plan-sponsor-table.component').then(
                                (m) => m.PlanSponsorTableComponent
                            ),
                        title: 'Plan Sponsors',
                        data: {
                            breadcrumb: new Breadcrumb('Plan Sponsors')
                        },
                        resolve: {
                            tabs: mainTabsResolver
                        }
                    },
                    {
                        path: 'divisions',
                        loadComponent: () =>
                            import('./division-table/division-table.component').then((m) => m.DivisionTableComponent),
                        title: 'Divisions',
                        data: {
                            breadcrumb: new Breadcrumb('Divisions')
                        },
                        resolve: {
                            tabs: mainTabsResolver
                        }
                    },
                    {
                        path: 'payroll-activity',
                        loadComponent: () =>
                            import('./payroll-table/payroll-table.component').then((m) => m.PayrollTableComponent),
                        canActivate: [hasPermissionsGuard('LIST_PAYROLL')],
                        title: 'Payroll Activity',
                        data: {
                            breadcrumb: new Breadcrumb('Payroll Activity')
                        },
                        resolve: {
                            tabs: mainTabsResolver
                        }
                    },
                    {
                        path: 'deduction-updates',
                        canActivate: [hasPermissionsGuard('LIST_UPDATES')],
                        title: 'Deduction Updates',
                        data: {
                            breadcrumb: new Breadcrumb('Deduction Updates')
                        },
                        loadChildren: () =>
                            import('./deduction-updates/deduction-updates.routes').then(
                                (m) => m.DEDUCTION_UPDATES_ROUTES
                            )
                    },
                    {
                        path: 'record-keepers',
                        loadComponent: () =>
                            import('./record-keeper-table/record-keeper-table.component').then(
                                (m) => m.RecordKeeperTableComponent
                            ),
                        canActivate: [hasPermissionsGuard('LIST_RECORD_KEEPER')],
                        title: 'Record Keepers',
                        data: {
                            breadcrumb: new Breadcrumb('Record Keepers')
                        },
                        resolve: {
                            tabs: mainTabsResolver
                        }
                    },
                    {
                        path: 'user-management',
                        loadChildren: () =>
                            import('./user-management/user-management.routes').then((m) => m.USER_MANAGEMENT_ROUTES),
                        canActivate: [
                            hasPermissionsGuard(
                                ['CREATE_USER_RESERVATION', 'EDIT_USER_ROLE', 'LIST_USER_RESERVATION', 'LIST_USER'],
                                'SOME'
                            )
                        ],
                        title: 'User Management'
                    },
                    {
                        path: 'plan-sponsor/:planSponsorId',
                        loadChildren: () =>
                            import('./plan-sponsor/plan-sponsor.routes').then((m) => m.PLAN_SPONSOR_ROUTES),
                        canActivate: [
                            hasPermissionsGuard(
                                ['CREATE_PLAN_SPONSOR', 'EDIT_PLAN_SPONSOR', 'LIST_PLAN_SPONSOR'],
                                'SOME'
                            )
                        ],
                        title: 'Plan Sponsor',
                        resolve: {
                            breadcrumb: planSponsorBreadcrumbResolver
                        }
                    },
                    {
                        path: 'tpe-access/:tpeType',
                        title: 'Third-Party Access Management',
                        canActivate: [hasPermissionsGuard('GRANT_PLAN_SPONSOR_ACCESS')],
                        data: {
                            breadcrumb: new Breadcrumb('Third-Party Access Management')
                        },
                        children: [
                            {
                                path: ':tpeIdentifier',
                                loadComponent: () =>
                                    import('./tpe-access/tpe-access.component').then((m) => m.TpeAccessComponent)
                            },
                            {
                                path: '',
                                pathMatch: 'full',
                                loadComponent: () =>
                                    import('./tpe-access/tpe-access.component').then((m) => m.TpeAccessComponent)
                            }
                        ]
                    },
                    {
                        path: 'invite',
                        loadChildren: () => import('./invite/invite.routes').then((m) => m.INVITE_ROUTES)
                    },
                    {
                        path: 'settings',
                        title: 'Settings',
                        loadChildren: () => import('./settings/settings.routes').then((m) => m.SETTINGS_ROUTES)
                    }
                ]
            },
            {
                path: '**',
                redirectTo: 'plan-sponsors'
            }
        ]
    }
];
