import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import type { ParamValidatorFn } from '../types/param-validator-fn';

export const validateQueryParamGuard =
    (paramName: string, validators: ParamValidatorFn[], redirectTo: (route: ActivatedRouteSnapshot) => UrlTree) =>
    (route: ActivatedRouteSnapshot) => {
        const paramValue = route.queryParamMap.get(paramName);

        if (validators.some((validator) => !validator(paramValue))) {
            return redirectTo(route);
        }

        return true;
    };
