import { inject } from '@angular/core';

import { UserService } from '../../lib/services/user.service';
import { NavigationHelpers } from '../services/navigation-helpers.service';

async function authGuardFn(userService: UserService, navigationHelpers: NavigationHelpers) {
    const logoutUrlTree = navigationHelpers.createLogoutUrlTreeWithRedirect();

    try {
        const me = await userService.getMe();

        if (!me) {
            return logoutUrlTree;
        }

        return true;
    } catch (error) {
        return logoutUrlTree;
    }
}

export const authGuard = () => {
    const navigationHelpers = inject(NavigationHelpers);
    const userService = inject(UserService);
    return authGuardFn(userService, navigationHelpers);
};
