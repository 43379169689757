@if (!isStaticPage(locationPath)) {
    <div>
        <p-toast position="top-center" key="center" />
        <p-toast position="top-right" key="main" />
        <!-- Confirm key should not be used for non confirmation messages, as it causes the user to be logged out -->
        <p-toast position="top-right" key="confirm" (onClose)="onReject()" [baseZIndex]="5000">
            <ng-template let-message pTemplate="message">
                <div class="flex flex-column" style="flex: 1">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{ message.summary }}</h4>
                        <p>{{ message.detail }}</p>
                    </div>
                    <div class="grid p-fluid">
                        <button
                            type="button"
                            pButton
                            (click)="onConfirm()"
                            [label]="message.data?.confirmText"
                            class="p-button-primary mt-3 mr-3 mb-0 ml-3"
                        ></button>
                        <button
                            pButton
                            type="button"
                            [label]="message.data?.rejectText"
                            class="p-button-secondary mt-3 mr-3 mb-0 ml-3"
                            (click)="onReject()"
                        ></button>
                    </div>
                </div>
            </ng-template>
        </p-toast>
    </div>
}

<router-outlet />
