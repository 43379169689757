import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { TPAConfigList } from '@pi/pi-configuration/src/configuration/third-party-administrators/list';
import type { TPAConfig } from '@pi/pi-configuration/src/interfaces/third-party-administrators/tpa-config';

import { TPAService } from '../../lib/services/tpa.service';

export const supportedTPAGuard = (route: ActivatedRouteSnapshot) => {
    const tpaService = inject(TPAService);

    const supportedTPA = String(route.params.supportedtpa ?? route.parent?.params.supportedtpa).toLowerCase();

    const matched: TPAConfig | undefined = TPAConfigList.find((TPA) => TPA.identifier.toLowerCase() === supportedTPA);
    if (matched) {
        tpaService.updateTPA(matched);
        return true;
    }
    return createUrlTreeFromSnapshot(route, ['/payrollintegrations/login']);
};
