import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';

import { accessDenied } from './access-denied';
import { GlobalMessagesService } from '../../lib/services/global-messages.service';
import { RecordKeeperService } from '../../lib/services/record-keeper.service';
import { UserService } from '../../lib/services/user.service';

async function recordKeeperGuardFn(
    route: ActivatedRouteSnapshot,
    userService: UserService,
    recordKeeperService: RecordKeeperService,
    globalMessagesService: GlobalMessagesService
): Promise<true | UrlTree> {
    const me = await userService.getMe();
    if (!me || !me.user) {
        return accessDenied(route, globalMessagesService);
    }

    const supportedRecordKeeper = String(
        route.params.supportedRecordKeeper ?? route.parent?.params.supportedRecordKeeper
    ).toLowerCase();
    try {
        await recordKeeperService.loadRecordKeeper(supportedRecordKeeper);
    } catch (e) {
        // Fall back and redirect to a valid record keeper if possible
        const validRecordKeepers = await recordKeeperService.loadAll();
        if (validRecordKeepers.length > 0) {
            return createUrlTreeFromSnapshot(
                route,
                ['/', validRecordKeepers[0].url, ...route.url.slice(1).map((segment) => segment.path)],
                route.params,
                route.fragment
            );
        }
        return accessDenied(route, globalMessagesService);
    }

    return true;
}

export const recordKeeperGuard = (route: ActivatedRouteSnapshot): Promise<true | UrlTree> => {
    const userService = inject(UserService);
    const recordKeeperService = inject(RecordKeeperService);
    const globalMessagesService = inject(GlobalMessagesService);
    return recordKeeperGuardFn(route, userService, recordKeeperService, globalMessagesService);
};
