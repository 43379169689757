import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

import { RecordKeeperService } from '../../lib/services/record-keeper.service';

export const allowsNewAccountCreationGuard = (route: ActivatedRouteSnapshot) => {
    const recordKeeperService = inject(RecordKeeperService);

    return recordKeeperService.recordKeeperConfigBehaviorSubject.pipe(
        map((recordKeeper) => {
            if (
                recordKeeper &&
                (environment.allowUserCreationForAllRecordKeepers || recordKeeper.allowsNewAccountCreation)
            ) {
                return true;
            } else {
                return createUrlTreeFromSnapshot(route, ['/']);
            }
        })
    );
};
