import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { termsOfServiceVersion } from '@pi/pi-configuration/src/configuration/terms-of-service-version';

import { RecordKeeperService } from '../../lib/services/record-keeper.service';
import { UserService } from '../../lib/services/user.service';

async function userSetupGuardFn(
    route: ActivatedRouteSnapshot,
    userService: UserService,
    recordKeeperService: RecordKeeperService
) {
    const me = await userService.getMe();
    if (!me || !me.user) {
        return false;
    }

    if (
        me.user.accepted_terms_of_service_version !== termsOfServiceVersion || // User has not accepted terms and conditions
        me.roles.length === 0 // User has not created first plan sponsor (generated permissions)
    ) {
        const urlSegments = ['/'];
        const recordKeeperUrl = recordKeeperService.recordKeeperUrl;

        if (recordKeeperUrl) {
            urlSegments.push(recordKeeperUrl, 'setup');
        }

        return createUrlTreeFromSnapshot(route, urlSegments);
    }

    return true;
}

export const userSetupGuard = (route: ActivatedRouteSnapshot) => {
    const userService = inject(UserService);
    const recordKeeperService = inject(RecordKeeperService);
    return userSetupGuardFn(route, userService, recordKeeperService);
};
