import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';

import { UserService } from '../../lib/services/user.service';
import { LOGOUT_TEARDOWN_TOKEN } from '../tokens/logout-teardown-token';

export const logoutGuard = async (route: ActivatedRouteSnapshot) => {
    const userService = inject(UserService);
    const teardownFns = inject(LOGOUT_TEARDOWN_TOKEN);

    for (const teardownFn of teardownFns) {
        await teardownFn();
    }

    void userService.signOut();

    const urlSegments = ['/'];
    const queryParams = { ...route.queryParams };
    if (queryParams.supportedRecordKeeper) {
        urlSegments.push(queryParams.supportedRecordKeeper, 'login');
        delete queryParams.supportedRecordKeeper;
    }

    return createUrlTreeFromSnapshot(route, urlSegments, queryParams);
};
