import { provideHttpClient, withFetch, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxStripeModule } from 'ngx-stripe';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

import { AppComponent } from './app/app.component';
import { ROUTES } from './app/app.routes';
import { DIVISION_VERIFICATION_CACHE_TEARDOWN } from './app/division/services/division-verification-cache.service';
import { BillingService } from './app/lib/services/billing.service';
import { DataService } from './app/lib/services/data.service';
import { DynamicFormsService } from './app/lib/services/dynamic-forms.service';
import { RECORD_KEEPER_CACHE_TEARDOWN, RecordKeeperService } from './app/lib/services/record-keeper.service';
import { TPAService } from './app/lib/services/tpa.service';
import { authInterceptor } from './app/shared/http-interceptors/auth.interceptor';
import { basePathInterceptor } from './app/shared/http-interceptors/base-path.interceptor';
import { requestHeadersInterceptor } from './app/shared/http-interceptors/request-headers.interceptor';
import { retryInterceptor } from './app/shared/http-interceptors/retry.interceptor';
import { sentryReportingInterceptor } from './app/shared/http-interceptors/sentry-reporting.interceptor';
import { ConcatTitleStrategyProvider } from './app/shared/services/concat-title-strategy.service';
import { DIVISION_CACHE_TEARDOWN } from './app/shared/services/division-cache.service';
import { PLAN_SPONSOR_CACHE_TEARDOWN } from './app/shared/services/plan-sponsor-cache.service';
import { ROLE_CACHE_TEARDOWN } from './app/shared/services/role-cache.service';
import { StorageService } from './app/shared/services/storage.service';
import { THIRD_PARTY_ENTITY_CACHE_TEARDOWN } from './app/shared/services/third-party-entity-cache.service';
import { environment } from './environments/environment';

if (environment.production) {
    Sentry.init({
        environment: environment.sentryEnvironmentName ?? 'unknown',
        dsn: 'https://32cd1db89d544d499fdf81dbe5e5bef6@o1064254.ingest.sentry.io/6054981',
        integrations: [Sentry.browserTracingIntegration()],
        tracePropagationTargets: ['payrollintegrationsapp.com', 'payrollintegrationsdemo.com'],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5
    });

    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserAnimationsModule,
            BrowserModule,
            NgxStripeModule.forRoot(environment.stripePublishableKey),
            QRCodeModule
        ),
        provideHttpClient(
            withFetch(),
            withXsrfConfiguration({
                cookieName: 'XSRF-TOKEN',
                headerName: 'X-XSRF-TOKEN'
            }),
            withInterceptors([
                basePathInterceptor,
                requestHeadersInterceptor,
                authInterceptor,
                retryInterceptor,
                sentryReportingInterceptor
            ])
        ),
        provideRouter(ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' }), withComponentInputBinding()),
        RecordKeeperService,
        TPAService,
        StorageService,
        DataService,
        DialogService,
        ConfirmationService,
        DynamicFormsService,
        MessageService,
        BillingService,
        ConcatTitleStrategyProvider,
        {
            provide: APP_INITIALIZER,
            useFactory: (router: Router, dialogService: DialogService) => () => {
                router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                    dialogService.dialogComponentRefMap?.forEach((comp, key) => {
                        key.close(); // Close any open modals when navigating away from a page
                    });
                });
            },
            multi: true,
            deps: [Router, DialogService]
        },
        // Sentry Providers
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // These do not play well with our modals
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        RECORD_KEEPER_CACHE_TEARDOWN,
        PLAN_SPONSOR_CACHE_TEARDOWN,
        DIVISION_CACHE_TEARDOWN,
        DIVISION_VERIFICATION_CACHE_TEARDOWN,
        THIRD_PARTY_ENTITY_CACHE_TEARDOWN,
        ROLE_CACHE_TEARDOWN
    ]
}).catch((err) => console.error(err));
